import React, { Suspense } from "reactn";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { PageLoading } from "@ant-design/pro-layout";

import { PublicRoute, PrivateRoute, AuthRoute } from "utils/routes";
import { LoaderStore } from "utils/global";
import { useEffect } from "react";
import { ConfigProvider, theme as antdTheme } from 'antd';
import enUS from 'antd/locale/en_US';
import { ThemeProvider as GlobalThemeProvider, useTheme } from './utils/global/ThemeContext';

// Auth Imports
const AuthLogin = React.lazy(() => import("pages/Auth/Login"));
const ResetPassword = React.lazy(() => import("pages/Auth/ResetPassword"));
const ResetWithToken = React.lazy(() => import("pages/Auth/ResetWithToken"));
const OnboardingMain = React.lazy(() => import("pages/Auth/OnboardingMain"));
const OnboardingFirstVisit = React.lazy(() => import("pages/Auth/OnboardingFirstVisit"));
const OAuthCallback = React.lazy(() => import("pages/Auth/OAuthCallback"));

// Settings
const Settings = React.lazy(() => import("pages/Settings/Settings"));

// Orgs
const Orgs = React.lazy(() => import("pages/Org/List"));

// Schedule
const ScheduleChannels = React.lazy(() => import("pages/Schedule/Channels"));

// Event
const EventSingle = React.lazy(() => import("pages/Event/Single"));
const EventActiveList = React.lazy(() => import("pages/Event/Active"));

// Broadcast
const BroadcastMy = React.lazy(() => import("pages/Dashboard/My"));
const BroadcastSingle = React.lazy(() => import("pages/Broadcast/Page"));

// Experience Composer Page
const ComposerPage = React.lazy(() => import("pages/Broadcast/ComposerPage"));

// Watch
const WatchSingle = React.lazy(() => import("pages/Watch/Single"));

// Orgs
const OrgList = React.lazy(() => import("pages/Orgs"));

// Orgs
const UserList = React.lazy(() => import("pages/User/List"));
const Moderation = React.lazy(() => import("pages/Moderation"));
const ModerationList = React.lazy(() => import("pages/Moderation/List"));
const ThemeEditor = React.lazy(() => import("pages/Theme/ThemeEditor"));

// Webhooks
const Webhooks = React.lazy(() => import("pages/Webhooks"));

// Web Embed
const WebEmbed = React.lazy(() => import("pages/WebEmbed"));

// Uploaded Videos CRUD
const Media = React.lazy(() => import("components/Media"));
const ViewUploadedMedia = React.lazy(() => import("components/Media/View"));

// Exception Imports
const Exception404 = React.lazy(() => import("pages/Exception/404"));

// Add this new import for the ClipsPage
const ClipsPage = React.lazy(() => import("pages/Clips"));

// Helper component to handle theme-dependent configuration
function SuspendedApp({ children }) {
  const { loading, theme } = useTheme();

  if (loading || !theme) {
    return <PageLoading />;
  }

  return children;
}

function ThemedApp() {
  const { theme: currentTheme } = useTheme();

  console.log("THEME:", currentTheme);

  useEffect(() => {
    // Update page title when theme loads
    if (currentTheme?.app_name) {
      document.title = currentTheme.app_name;
      console.log("NAME:", currentTheme.app_name)
    }
  }, [currentTheme]);

  
  // Convert our theme to Ant Design token format
  const convertThemeToAntd = (theme) => ({
    token: {
      // Seed Tokens
      colorPrimary: theme?.colors?.primary || '#6927f4',
      colorError: theme?.colors?.error || '#f5222d',
      colorWarning: theme?.colors?.warning || '#faad14',
      colorSuccess: theme?.colors?.success || '#52c41a',
      colorInfo: theme?.colors?.link || '#1890ff',
      colorHighlight: theme?.colors?.highlight || '#469578',
      colorLink: theme?.colors?.link || '#1890ff',
      
      // Font
      fontFamily: theme?.typography?.fontFamily?.primary || '"Proxima Nova", Helvetica, Arial, sans-serif',
      fontSize: parseInt(theme?.typography?.fontSize?.small || '14'),
      
      // Alias Tokens - Text Colors
      colorText: theme?.colors?.text?.primary || 'rgba(255, 255, 255, 0.85)',
      colorTextBase: theme?.colors?.text?.primary || '#ffffff',
      colorTextSecondary: theme?.colors?.text?.secondary || 'rgba(255, 255, 255, 0.65)',
      colorTextTertiary: theme?.colors?.text?.secondary || 'rgba(255, 255, 255, 0.45)',
      colorTextQuaternary: theme?.colors?.disabled || 'rgba(255, 255, 255, 0.25)',
      colorTextDisabled: theme?.colors?.disabled || 'rgba(255, 255, 255, 0.25)',
      colorTextHeading: theme?.colors?.text?.heading || 'rgba(255, 255, 255, 0.90)',
      
      // Base background color - This affects the overall base background
      colorBgBase: theme?.colors?.background?.base || '#111',
      colorBgBody: theme?.colors?.background?.secondary || '#f0f2f5',
      
      // Layout colors - Main background gradient
      colorBgLayout: theme?.colors?.background?.gradient?.[1] || '#000', // Main background (while testing)
          
      // Alias Tokens - Background Colors
      colorBgContainer: theme?.colors?.background?.input || '#27262b', // Input background (while testing)
      colorBgElevated: theme?.colors?.background?.modal || '#1a1a1a', // Modal background (while testing)
      colorBgLayout: theme?.colors?.background?.gradient?.[1] || '#000000',
      colorBgSpotlight: theme?.colors?.background?.spotlightmask || 'rgba(10, 10, 10, 0.75)',
      colorBgMask: theme?.colors?.background?.spotlightmask || 'rgba(10, 10, 10, 0.75)',
      
      // Input backgrounds
      colorBgContainerDisabled: theme?.colors?.background?.inputDisabled || '#1a1a1a',
      colorBgTextHover: theme?.colors?.background?.inputHover || '#2a2a2a',
      colorBgTextActive: theme?.colors?.background?.inputActive || '#1a1a1a',

      // Alias Tokens - Border
      colorBorder: theme?.colors?.border || '#48474D', // Inputs and buttons border
      colorBorderSecondary: theme?.colors?.border2 || 'rgba(40, 40, 40, 0.1)', // Header border
      borderRadius: parseInt(theme?.layout?.borderRadius || '8'),
      borderRadiusLG: parseInt(theme?.layout?.borderRadius || '8'),
      borderRadiusSM: parseInt(theme?.layout?.borderRadiusBase || '4'),
      
      // Alias Tokens - Fill Colors
      colorFill: theme?.colors?.surface?.quiet || '#22242a',
      colorFillSecondary: theme?.colors?.surface?.level2 || '#1A1A1A',
      colorFillTertiary: theme?.colors?.surface?.level3 || '#2A2A2A',
      colorFillQuaternary: theme?.colors?.surface?.level4 || '#3A3A3A',
      
      // Box Shadow
      boxShadow: theme?.layout?.boxShadow || '0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
    },
    components: {
      // // Component level customization
      // Input: {
      //   colorBgContainer: theme?.colors?.background?.input || '#27262b',
      //   colorBgContainerDisabled: theme?.colors?.background?.input || '#27262b',
      //   colorTextDisabled: theme?.colors?.text?.secondary || 'rgba(255, 255, 255, 0.65)',
      //   algorithm: true,
      // },
      // Select: {
      //   colorBgContainer: theme?.colors?.background?.input || '#27262b',
      //   colorBgContainerDisabled: theme?.colors?.background?.input || '#27262b',
      //   colorTextDisabled: theme?.colors?.text?.secondary || 'rgba(255, 255, 255, 0.65)',
      //   algorithm: true,
      // },
      // DatePicker: {
      //   colorBgContainer: theme?.colors?.background?.input || '#27262b',
      //   colorBgContainerDisabled: theme?.colors?.background?.input || '#27262b',
      //   colorBgElevated: theme?.colors?.background?.input || '#27262b',
      //   colorTextDisabled: theme?.colors?.text?.secondary || 'rgba(255, 255, 255, 0.65)',
      //   algorithm: true,
      // },
      // Radio: {
      //   colorBgContainer: theme?.colors?.background?.input || '#27262b',
      //   colorBgContainerDisabled: theme?.colors?.background?.input || '#27262b',
      //   colorTextDisabled: theme?.colors?.text?.secondary || 'rgba(255, 255, 255, 0.65)',
      //   algorithm: true,
      //   // Radio.Button specific styles
      //   radioButtonBg: theme?.colors?.background?.input || '#27262b',
      //   radioButtonCheckedBg: theme?.colors?.background?.input || '#27262b',
      //   radioButtonColor: theme?.colors?.text?.primary || 'rgba(255, 255, 255, 0.85)',
      //   radioButtonHoverColor: theme?.colors?.text?.primary || 'rgba(255, 255, 255, 0.85)',
      //   radioButtonPaddingHorizontal: 16,
      // },
      // Modal: {
      //   colorBgElevated: theme?.colors?.background?.modal || '#1a1a1a',
      //   algorithm: true,
      // },
      // Button: {
      //   algorithm: true,
      // }
    },
    algorithm: antdTheme.darkAlgorithm,
  });
  
  useEffect(() => {
    if (currentTheme) {
      // Convert theme values to Less variables if needed
      const lessVars = Object.keys(currentTheme).reduce((acc, key) => {
        if (typeof currentTheme[key] === 'string') {
          acc[`@${key.replace(/([A-Z])/g, '-$1').toLowerCase()}`] = currentTheme[key];
        }
        return acc;
      }, {});

      window.less
        ?.modifyVars(lessVars)
        .catch(e => console.log("Error updating default theme", e));
      }
      
  }, [currentTheme]);

  // Get subdomain from window.location
  const subdomain = window.location.hostname.split('.')[0];

  const convertedTheme = convertThemeToAntd(null);

  return (
    <ConfigProvider 
      locale={enUS} 
      theme={{
        algorithm: antdTheme.darkAlgorithm,
        token: convertedTheme.token,
        components: convertedTheme.components
      }}
    >
      <SuspendedApp>
        <Router>
          <Suspense fallback={<PageLoading />}>
            <Routes>
              {/* Root */}
              <Route path="/" exact element={<Navigate to="/broadcast/my" replace />} />

              {/* Auth */}
              <Route path="/auth" element={<Navigate to="/auth/login" replace />} />

              <Route path="/auth/login" element={<AuthRoute><AuthLogin /></AuthRoute>} />
              <Route path="/auth/reset" element={<AuthRoute><ResetPassword /></AuthRoute>} />
              <Route path="/auth/reset/:token" element={<AuthRoute><ResetWithToken /></AuthRoute>} />
              <Route path="/auth/onboarding" element={<AuthRoute><OnboardingMain /></AuthRoute>} />
              <Route path="/auth/onboarding/:token" element={<AuthRoute><OnboardingFirstVisit /></AuthRoute>} />
              <Route path="/login/oauth/callback" element={<AuthRoute><OAuthCallback /></AuthRoute>} />

              {/* Settings */}
              <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />

              {/* Developer */}
              <Route path="/webhooks" element={<PrivateRoute><Webhooks /></PrivateRoute>} />
              <Route path="/web-embed" element={<PrivateRoute><WebEmbed /></PrivateRoute>} />

              {/* Event */}
              <Route path="/event" element={<PrivateRoute><Navigate to="/schedule/events" replace /></PrivateRoute>} />
              <Route path="/event/active" element={<PrivateRoute><EventActiveList /></PrivateRoute>} />
              <Route path="/event/:event" element={<PrivateRoute><EventSingle /></PrivateRoute>} />

              {/* Broadcast/Stream */}
              <Route path="/broadcast" element={<Navigate to="/schedule/events" replace />} />
              <Route path="/broadcast/my" element={<PrivateRoute><BroadcastMy /></PrivateRoute>} />
              <Route path="/broadcast/:stream" element={<PrivateRoute noHeader={true}><BroadcastSingle /></PrivateRoute>} />

              {/* Experience Composer Page */}
              <Route path="/ec" element={<PublicRoute ecPage={true}><ComposerPage /></PublicRoute>} />

              {/* Watch Page */}
              <Route path="/watch/:stream" element={<PublicRoute><WatchSingle /></PublicRoute>} />

              {/* Orgs */}
              <Route path="/orgs/list" element={<PrivateRoute><OrgList /></PrivateRoute>} />

              {/* Hosts */}
              <Route path="/user/list" element={<PrivateRoute><UserList /></PrivateRoute>} />
              <Route path="/moderation" element={<PrivateRoute><Moderation /></PrivateRoute>} />
              <Route path="/moderation/list" element={<PrivateRoute><ModerationList /></PrivateRoute>} />
              <Route path="/theme" element={<PrivateRoute><ThemeEditor /></PrivateRoute>} />

              {/* Uploaded Videos */}
              <Route path="/media" element={<PrivateRoute><Media /></PrivateRoute>} />
              <Route path="/videos/:id" element={<PrivateRoute><ViewUploadedMedia type="video"/></PrivateRoute>} />
              <Route path="/images/:id" element={<PrivateRoute><ViewUploadedMedia type="image"/></PrivateRoute>} />
              <Route path="/overlays/:id" element={<PrivateRoute><ViewUploadedMedia type="overlay"/></PrivateRoute>} />
              <Route path="/backgrounds/:id" element={<PrivateRoute><ViewUploadedMedia type="background"/></PrivateRoute>} />

              {/* Exceptions */}
              <Route path="*" element={<PublicRoute><Exception404 /></PublicRoute>} />

              {/* Add this new route for the Clips page */}
              <Route path="/clips" element={<PrivateRoute><ClipsPage /></PrivateRoute>} />

            </Routes>
          </Suspense>
        </Router>
      </SuspendedApp>
    </ConfigProvider>
  );
}

function App() {
  return (
    <LoaderStore>
      <GlobalThemeProvider>
        <ThemedApp />
      </GlobalThemeProvider>
    </LoaderStore>
  );
}

export default App;

